import React from 'react';

import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const Image = ({ objectFit = 'contain', data, alt, className = '', imgStyle }) => {
    return (
        <GatsbyImage
            image={getImage(data.file)}
            className={className}
            objectFit={objectFit}
            alt={alt}
            imgStyle={imgStyle}
        />
    );
};

export default Image;
