import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { form, photo, layout } from './kontakt.module.scss';

import SEO from '../components/seo';
import Main from '../layouts/main';
import Image from '../components/atoms/image';
import ContactForm from '../components/molecules/contact-form';
import SocialList from '../components/molecules/social-list';

const ContactPage = () => {
    const data = useStaticQuery(query);

    return (
        <Main className={layout}>
            <SEO
                title="Kontakt"
                structuredData={{
                    '@type': 'ContactPage',
                    name: `Kontakt | ${data.site.siteMetadata.title}`,
                    url: `${data.site.siteMetadata.url}/kontakt/`,
                }}
            />
            <div className={photo}>
                <Image alt="photo owners" data={data} />
            </div>
            <div className={form}>
                <ContactForm />
                <SocialList
                    light={false}
                    header="Znajdź nas również na:"
                    externalLinks={data.allExternalLink.edges.map((edge) => edge.node)}
                />
            </div>
        </Main>
    );
};

const query = graphql`
    {
        file(relativePath: { eq: "photo-owners-1.png" }) {
            childImageSharp {
                gatsbyImageData(width: 800, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        allExternalLink {
            edges {
                node {
                    linkId
                    name
                    uri
                }
            }
        }
        site {
            ...siteFields
        }
    }
`;

export default ContactPage;
