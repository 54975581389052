import React from 'react';
import { ErrorMessage, Field } from 'formik';
import ReCAPTCHA from 'react-google-recaptcha';
import PropTypes from 'prop-types';

import { config } from '../../../config';

import TextError from './text-error';

const Captcha = ({ name, className = '' }) => {
    return (
        <div className={className}>
            <Field name={name}>
                {({ form }) => (
                    <ReCAPTCHA
                        sitekey={config.captcha.clientKey}
                        onChange={(value) => form.setFieldValue(name, value || '')}
                    />
                )}
            </Field>
            <ErrorMessage name={name} component={TextError} />
        </div>
    );
};

Captcha.propTypes = {
    name: PropTypes.string.isRequired,
    className: PropTypes.string,
};

Captcha.defaultProps = {
    className: '',
};

export default Captcha;
