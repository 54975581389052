import React from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { onlyText } from 'react-children-utilities';

import {
    container,
    form,
    lowOpacity,
    title,
    formBox,
    captcha,
    errorBox,
    button,
    rodoNote,
} from './contact-form.module.scss';
import FaqEmotics from '../../assets/images/svg/faq-emotics.svg';
import { config } from '../../config';
import { postContactMe } from '../../communication/contact';
import { formatFormikErrors } from '../../communication/utils';
import { copy } from '../../copy';

import Title from '../atoms/title';
import Emotic from '../atoms/emotic';
import Input from '../atoms/form-poc/input';
import Button from '../atoms/button';
import Loader from '../atoms/loader';
import TextError from '../atoms/form-poc/text-error';
import Captcha from '../atoms/form-poc/captcha';
import Checkbox from '../atoms/form-poc/checkbox';
import ReadMore from '../atoms/read-more';

const { required, email } = config.messages.form;
const { loading, success, fail } = config.formsStatusMap;
const { consentsMap } = config;
const { consents } = copy;

const initialValues = {
    email: '',
    content: '',
    recaptcha: '',
    consents: {
        regulations: false,
        rodo: false,
    },
};

const validationSchema = Yup.object({
    email: Yup.string().email(email).required(required),
    content: Yup.string().required(required),
    recaptcha: Yup.string().required(required),
    consents: Yup.object({
        regulations: Yup.boolean().required(required).oneOf([true], required),
        rodo: Yup.boolean().required(required).oneOf([true], required),
    }),
});

const ContactForm = () => {
    const handleSubmit = async (formValues, formikBag) => {
        formikBag.setStatus(loading);

        const data = {
            email: formValues.email,
            content: formValues.content,
            consents: [
                {
                    source: consentsMap.source.contactForm,
                    key: consentsMap.key.regulations,
                    content: onlyText(consents.regulations),
                    agreed: formValues.consents.regulations ? 1 : 0,
                },
                {
                    source: consentsMap.source.contactForm,
                    key: consentsMap.key.rodo,
                    content: consents.rodo,
                    agreed: formValues.consents.regulations ? 1 : 0,
                },
            ],
        };

        postContactMe(data)
            .then(() => {
                formikBag.setStatus(success);
            })
            .catch((error) => {
                formikBag.setStatus(fail);
                formikBag.setErrors(formatFormikErrors(error));
            });
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formik) => (
                <div className={container}>
                    {formik.status === loading && (
                        <Loader fullContainer={true} noBackground={true} />
                    )}
                    <Form
                        className={`
                            ${form}
                            ${formik.status === loading ? lowOpacity : ''}
                        `}
                    >
                        <Emotic emotic={FaqEmotics} />
                        <Title className={title}>
                            {formik.status === success
                                ? 'Dziękujemy za wiadomość, skontaktujemy sie z Tobą wkrótce!'
                                : 'Skontaktuj się z nami'}
                        </Title>
                        {formik.status !== success && (
                            <>
                                <Input
                                    name="email"
                                    placeholder="Email"
                                    // eslint-disable-next-line jsx-a11y/no-autofocus
                                    autoFocus
                                    containerClass={formBox}
                                />
                                <Input
                                    name="content"
                                    as="textarea"
                                    placeholder="Treść"
                                    rows={5}
                                    containerClass={formBox}
                                />
                                <Checkbox name="consents.regulations" containerClass={formBox}>
                                    {consents.regulations}
                                </Checkbox>
                                <div className={rodoNote}>
                                    <ReadMore>{consents.rodoDisclaimer}</ReadMore>
                                </div>
                                <Checkbox name="consents.rodo" containerClass={formBox}>
                                    {consents.rodo}
                                </Checkbox>
                                <Captcha name="recaptcha" className={captcha} />
                                {formik.errors.global && (
                                    <div className={errorBox}>
                                        <TextError>{formik.errors.global}</TextError>
                                    </div>
                                )}
                                <Button color="yellow" className={button}>
                                    Wyślij
                                </Button>
                            </>
                        )}
                    </Form>
                </div>
            )}
        </Formik>
    );
};

export default ContactForm;
